<template>
  <component
    :is="whitepaper === 'long' ? 'Long' : 'Short'"
    :customCampaign
    :page
  />
</template>

<script setup lang="ts">
import type { Props } from './types';

// components
import Short from './Short.vue';
import Long from './Long.vue';

defineOptions({
  name: 'GetWhitePaper',
  components: {
    Long,
    Short,
  },
});

withDefaults(
  defineProps<Props>(),
  {
    page: 'white_paper',
  },
);

const { query: { whitepaper } } = useRoute();
</script>
