export type QueryZeroBounce = {
  api_key: string;
  email: string;
  ip_address?: string;
  timeout?: number,
};

type ResponseZeroBounceSuccess = {
  address: string;
  status: 'valid' | 'invalid';
  sub_status: string;
  free_email: boolean;
  did_you_mean: string | null;
  account: string;
  domain: string;
  domain_age_days: string;
  smtp_provider: string;
  mx_record: string;
  mx_found: string;
  firstname: string;
  lastname: string;
  gender: string;
  country: string;
  region: string;
  city: string;
  zipcode: string;
  processed_at: string;
}

type ResponseZeroBounceError = {
  error: string
}

export type ResponseZeroBounce = ResponseZeroBounceSuccess | ResponseZeroBounceError;

const ALLOWED_DOMAINS = [
  'guest-post-service.com',
  'thetopdevelopers.com',
  'cohesity.com',
  'unitrends.com',
  'acronis.com',
  'arcserve.com',
]

function buildQueryParams(params: {}) {
  return new URLSearchParams(params).toString();
}
export async function useZeroBounceValidation(email: string, ip: string | null) {
  const {
    public: { env, zeroBounceApi: baseUrl, zeroBounceKey: api_key },
  } = useRuntimeConfig();

  const emailDomain = email.split('@')[1];

  if (ALLOWED_DOMAINS.includes(emailDomain)) return

  const queryParams = buildQueryParams({
    api_key,
    email,
    ip_address: ip,
    timeout: 5,
  });

  await new Promise(resolve => setTimeout(resolve, 500));

  try {
    const response = await fetch(`${baseUrl}?${queryParams}`);

    if (!response.ok) {
      throw new Error(`Network response was not ok. Status: ${response.status}; Message: ${response.statusText}`);
    }

    const data: ResponseZeroBounce = await response.json();

    if ('error' in data) {
      throw new Error(`ZeroBounce API Error: ${data.error}`);
    }

    return data.status;

  } catch (error) {
    console.error("Error during ZeroBounce validation:", error);
  }
}
